import { getImagePathObj, getOssKey, isOssKey } from '@/utils/kerquUtil'
import { uploadTempFile } from '@/kerqu/upload'
import { myCookie } from '@/kerqu/cookie'

export const interceptApiUrl = (route: string) => {
  if (route.startsWith('/view?') || route.startsWith('/vhs/viewvideo?')) {
    const searchParams = new URLSearchParams(
      route.substring(route.indexOf('?') + 1)
    )
    const params = new URLSearchParams()
    const subfolder = searchParams.get('subfolder')
    let url = searchParams.get('filename')?.split(' ')[0]
    if (!url) return '/view'
    if (/^https?:\/\//.test(url) && isOssKey(url)) {
      url = getOssKey(url)
    }
    if (subfolder?.startsWith('temp/') && !url.startsWith('temp/')) {
      url = subfolder + '/' + url
    }
    params.set('filename', url)
    params.set('originalImage', 'true')
    return '/api/comfyui/view?' + params.toString()
  }
}

const wrapResponse = (data: any) => {
  return {
    status: 200,
    json: async () => data
  } as Response
}

export const interceptFetchApi = async (
  route: string,
  options?: RequestInit
) => {
  if (route.includes('/upload/mask')) {
    const formData = options!.body as FormData
    const originalImageRef = JSON.parse(formData.get('original_ref') as string)
    const originalOssKey = getOssKey(originalImageRef.filename as string)

    const maskFile = formData.get('image') as File

    const maskFileName = getForkedImageOssKey(originalOssKey, 'mask')
    const ossKey = await uploadTempFile(maskFile, maskFileName)

    const res = await fetch('/api/upload/mask', {
      method: 'POST',
      headers: {
        'content-type': 'application/json'
      },
      body: JSON.stringify({
        image: originalOssKey,
        base64: ossKey
      })
    }).then((res) => res.json())

    return wrapResponse(getImagePathObj(getOssKey(res.data as string)))
  } else if (route.includes('/upload/image')) {
    const formData = options!.body as FormData
    const imageFile = formData.get('image') as File

    const ossKey = await uploadTempFile(imageFile)
    return wrapResponse(getImagePathObj(ossKey as string))
  } else if (route === '/pysssss/workflows') {
    return wrapResponse([])
  } else if (route == '/impact/wildcards/list') {
    return wrapResponse({
      data: ['__samples/jewel__', '__samples/flower__']
    })
  } else if (route.includes('/settings/Comfy.CustomColorPalettes')) {
    return wrapResponse({})
  } else if (route === '/ttN/loras') {
    return wrapResponse([])
  } else if (route === '/embeddings') {
    return wrapResponse('')
  } else if (route === '/settings') {
    return fetch('/assets/settings.json', options)
  } else if (route === '/easyuse/image_chooser_message') {
    return wrapResponse({})
  } else if (route === '/DZ/debug') {
    return wrapResponse({
      enabled: false
    })
  } else if (route === '/mtb/server-info') {
    return wrapResponse({
      debug: false
    })
  }
}

const getOriginImageOssKey = (ossKey: string) => {
  if (!isOssKey(ossKey)) return ''
  let originOssKey = getOssKey(ossKey).replace(/__[a-zA-Z]+_\d+/, '')
  const originExtMatches = originOssKey.match(/\/\[([a-zA-Z0-9]+)]/)
  if (originExtMatches && originExtMatches.length > 1) {
    originOssKey = originOssKey.replace(
      /\.[a-zA-Z0-9]+$/,
      `.${originExtMatches[1]}`
    )
  }
  return originOssKey
}

const getForkedImageOssKey = (ossKey: string, type: string) => {
  if (!isOssKey(ossKey)) return ''
  return `${getOriginImageOssKey(ossKey).split('.')[0]}__${type}_${Date.now()}.png`
}

export const hasLogin = () => {
  return !!myCookie.get('kerqu-trader-token')
}
